$mobile: 640px;
$tablet: 768px;
$desktop: 1024px;

.container {
    display: flex;
    justify-content: center;
    place-items: center;
}
.card {
    margin-top: 5rem;
    width: 75rem;
    align-items: center;
    @media (max-width: $mobile) {
        margin-top: 0;
    }
}
.headerContainer {
    display: flex;
    padding-bottom: 1.25rem;
    @media (max-width: $mobile) {
        padding-bottom: 0;
        img {
            margin-top: 10px;
            width: 30px; 
            height: 30px;
        }
    }

}
.headerMargin {
    margin-left: 1rem;
}
.headerText {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.5rem;
    @media (max-width: $mobile) {
        font-size: 1.25rem;
    }
}
.headerAddress {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
    @media (max-width: $mobile) {
        font-weight: 500;
        font-size: 1rem;
        line-height: 0.5rem;
    }
}
.headerNavigation {
    padding-left: 1rem;
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
    @media (max-width: $mobile) {
        padding-left: 0.25rem;
        font-size: 0.875rem;
    }
}
.mainContainer {
    display: flex;
    margin-top: 2.5rem;
    @media (max-width: $mobile) {
        flex-direction: column;
        margin-top: 1rem;
    }
}
.propertyContainer {
    width: 40rem;
    @media (max-width: $mobile) {
        width: 100%;
    }
}
.propertyDetails {
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media (max-width: $mobile) {
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
    }
}
.quoteHeader {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.75rem;
    line-height: 1;
    font-weight: 700;
    padding-bottom: 1.5rem;
    @media (max-width: $mobile)  {
        font-size: 1.25rem;
        padding-bottom: 0.5rem;
        margin-top: 1rem;
    }
}
.priceContainer {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity));
    font-weight: 600;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media (min-width: $desktop){
        display: flex;
        align-items: flex-start;
        justify-content: center;
   }
    @media (max-width: $mobile)  {
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.quotePrice {
    font-size: 4.5rem;
    line-height: 0.65;
    @media (max-width: $mobile) {
        font-size: 1.75rem;
        line-height: 0.5;
        
    }
}
.quoteDollar {
    font-size: 2.75rem;
    line-height: 2.25rem;
    @media (max-width: $mobile) {
        font-size: 1.25rem;
    }
}
.quoteText {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media (max-width: $mobile) {
        font-size: 0.695rem;
        padding-bottom: 0.45rem;
    }

}
.textButton {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    @media (max-width: $mobile) {
        flex-wrap: nowrap;
        font-size: 0.75rem;
    }
}
.buttonContainer {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $mobile) {
        flex-direction: column;
    }
}
.emailButton {
    display: flex;
    align-items: center;
    font-weight: 700;
    border-radius: 5px;
    color: #637381;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    padding: 12px 25px;
    @media (max-width: $mobile) {
        margin-bottom: 0.75rem;
        margin-right: 0;
        font-size: 0.75rem;
    }
}
.callButton {
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: #637381;
    font-weight: 700;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    padding: 12px 25px;
    margin-left: 2rem;
    @media (max-width: $mobile) {
        padding: 12px 38px;
        margin-left: -0.1rem;
        font-size: 0.75rem;
    }
}
.policyDetails {
    text-align: center;
    padding-bottom: 1rem;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    @media (max-width: $mobile) {
        font-size: 1rem;
        padding-bottom: 0.25rem;
    }
}
.list {
    text-align: left;
    font-weight: 500;
    @media (max-width: $mobile) {
        font-size: 0.75rem;
    }
}