$mobile: 640px;
$tablet: 768px;
$desktop: 1024px;

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card {
    margin-top: 5rem;
    width: 70rem;
    @media (max-width: $mobile) {
        margin-top: 3rem;
    }
}
.addressContainer {
    display: flex;
    border-bottom-width: 1px;
    padding-bottom: 1.25rem;
    @media (max-width: $mobile) {
        padding-bottom: 0.5rem;
        img {
            margin-top: 10px;
            width: 30px; 
            height: 30px;
        }
    }
    
}
.innerAddressContainer {
    margin-left: 1rem;
    @media (max-width: $mobile) {
        margin-left: 0.5rem;
    }

}
.insuranceQuote {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.5rem;
    @media (max-width: $mobile) {
        font-size: 1.25rem;
   
    }
}
.insuranceAddress {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
    @media (max-width: $mobile) {
        font-weight: 500;
        font-size: 1rem;
        line-height: 0.5rem;
    }

}
.changeAddress {
    padding-left: 1rem;
    cursor: pointer;
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
    @media (max-width: $mobile) {
        padding-left: 0.25rem;
        font-size: 0.875rem;
    }

}
.insuranceHeader {
    font-weight: 700;
    font-size: 3.75rem;
    line-height: 1;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    @media (max-width: $mobile) {
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 0.75rem;
        margin-top: 1rem;
    }

}
.numberText {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2rem;
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
    @media (max-width: $mobile) {
        font-size: 1rem;
        line-height: 1;
        padding-bottom: 0.5rem;
    }

}
.phoneInput {
    border-width: 1px;
    border-radius: 0.25rem;
    width: 58%;
    padding-left: 2.75rem;
    margin-top: 1.5rem;
    height: 2.5rem;
    background-position: left;
    @media (max-width: $mobile) {
        width: 80%;
        padding-left: 2.5rem;
        margin-top: 1rem;
        height: 2rem;
    }
}
.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    @media (max-width: $mobile) {
        flex-direction: column;
    }
  }
  
  .buttonText {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 74px;
    margin-right: 1rem;
    background-color: #3CA745;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 1.05rem;
  
    @media (max-width: $mobile) {
      font-size: 0.875rem;
      width: auto;
      padding: 8px 45px;
      margin-bottom: 0.5rem;
      img {
        width: 18px;
        height: 18px;
      }

    }
  
    img {
      margin-right: 1.25rem;
    }
  }
  
  .buttonPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    background-color: #3056D3;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 1.05rem;
  
    @media (max-width: $mobile) {
      font-size: 0.875rem;
      width: auto;
      padding: 8px 24px;
      margin-right: 1rem;
    }
  }
  .text {
    text-align: center;
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-top: 1.5rem;
    @media (max-width: $mobile) {
        font-size: 0.75rem;
        line-height: 1.25;
        margin-top: 0.75rem;
    }

  }