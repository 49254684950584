$mobile: 640px;
$tablet: 768px;
$desktop: 1024px;

.searchContainer {
   margin-top: 10rem;
   @media (max-width: $mobile) {
    margin-top: 1rem;
   }
   @media (min-width: $desktop) {
    margin-top: 10rem;
   }
}
.instantQuote {
    font-size: 3.75rem;
    line-height: 1;
    padding-bottom: 1rem;
    font-weight: 700;
    @media (max-width: $mobile) {
        font-size: 1.5rem;  
        line-height: 1;
         }
    @media (min-width: $desktop){
        font-size: 3.75rem;
        line-height: 1;
        padding-bottom: 1rem;
        font-weight: 700;
    }
}
.cardInstantQuote {
    font-size: 1.75rem;
    line-height: 1;
    padding-bottom: 0.75rem;
    font-weight: 700; 
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    @media (max-width: $mobile) {
        font-size: 1.25rem;
        padding-top: 1rem;
        padding-bottom: 0.3rem;  
    }
    @media (min-width: $desktop) {
        font-size: 1.75rem;
        line-height: 1;
        padding-bottom: 0.75rem;
        font-weight: 700; 
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
.cardAddress {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
    // font-weight: 600;
    margin-top: 0.5rem;
    @media (max-width: $mobile) {
        font-size: 1rem;
        padding-bottom: 0.35rem;
    }

}
.dollar {
    font-size: 3rem;
    line-height: 2.25rem;
    @media (max-width: $mobile) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

}
.price {
    font-size: 4.5rem;
    line-height: 1;
    @media (max-width: $mobile) {
        font-size: 2rem;
    }

}
.instantCard {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 5rem;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    @media (max-width: $mobile) {
        margin-top: 1rem;
    }
}
.sampleText {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    @media (max-width: $mobile) {
        font-size: 0.65rem;
        line-height: 1;
    }

}
.policyHeader {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-bottom: 1rem;
    font-weight: 700;
    @media (max-width: $mobile) {
        font-size: 1rem;
        padding-bottom: 0.3rem;
    }

}
.policyDetails {
    text-align: left;
    font-weight: 500;
    @media (max-width: $mobile) {
        font-size: 0.775rem;
        line-height: 1.25;
    }
}